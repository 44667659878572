import useScripts from "../../utils/hooks/useScripts";
import Navigation from "../../components/Navigation/Navigation";
import React from "react";
import {isLoaded, useFirebaseConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {Pane, Spinner} from "evergreen-ui";
import {useNavigation} from "react-navi";

export default function Faculty() {

    useScripts('/js/jquery.min.js');
    useScripts('/js/bootstrap.bundle.min.js');
    useScripts('/js/feather.min.js');
    useScripts('/js/jquery.magnific-popup.min.js');
    useScripts('/js/magnific.init.js');
    useScripts('/js/isotope.js');
    useScripts('/js/portfolio.init.js');
    useScripts('/js/swiper.min.js');
    useScripts('/js/app.js');

    const navig = useNavigation()

    useFirebaseConnect([
        {
            path: '/website/faculty/'
        }
    ]);

    const faculty = useSelector((state: any) => state.firebase.ordered.website);

    return (
        <React.Fragment>
            <Navigation/>

            <section className="section">

                <div className="container mt-100 mt-60">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h2 className="title mb-0">Faculty</h2>
                            </div>
                        </div>
                    </div>
                    <hr/>

                    {!isLoaded(faculty) && <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                        <Spinner />
                    </Pane>}

                    <div className="row">
                        {isLoaded(faculty) && faculty.faculty !== undefined && faculty.faculty.map(item => (
                            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2" key={item.key} style={{cursor: "pointer"}} onClick={() => navig.navigate(`/faculty/${item.key}`)}>
                                <div className="d-flex align-items-center">
                                    <img src={item.value.image}
                                         className="avatar avatar-medium rounded-circle img-thumbnail" alt=""/>
                                    <div className="content ml-3">
                                        <h5 className="mb-0">
                                            <a href="javascript:void(0)" className="text-dark">{item.value.name}</a></h5>
                                        <small className="position text-muted">{item.value.designations}</small>
                                        <p><small className="position text-muted">{item.value.title}</small></p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
