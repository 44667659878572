import useScripts from "../../utils/hooks/useScripts";
import React from "react";
import Navigation from "../../components/Navigation/Navigation";
import {isLoaded, useFirestore, useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {Pane, Spinner} from "evergreen-ui";
import moment from "moment";

export default function Blogs({id}) {

    useScripts('js/jquery.min.js');
    useScripts('js/bootstrap.bundle.min.js');
    useScripts('js/feather.min.js');
    useScripts('js/jquery.magnific-popup.min.js');
    useScripts('js/magnific.init.js');
    useScripts('js/isotope.js');
    useScripts('js/portfolio.init.js');
    useScripts('js/swiper.min.js');
    useScripts('js/app.js');

    const fstore = useFirestore();

    useFirestoreConnect([{
        collection: 'blogs',
        doc: id,
        storeAs: `single-blog-${id}`
    }]);

    const blog = useSelector((state: any) => state.firestore.data[`single-blog-${id}`]);

    return (
        <React.Fragment>
            <Navigation/>
            {!isLoaded(blog) && <Pane display='flex' justifyContent='center'>
                <Spinner/>
            </Pane>}
            {isLoaded(blog) && <React.Fragment>
                <section className="bg-half-170 bg-animation-left half-home shadow d-table w-100">
                    <div className="container">
                        <div className="row align-items-center position-relative" style={{zIndex: 1}}>

                            <div className="col-lg-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                <div className="title-heading ml-lg-4">
                                    <h1>{blog.blogTitle}</h1>
                                    <div>
                                        <span className='text-muted'>{moment.unix(blog.createdAt.seconds).format('DD MMM YYYY')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                                <div
                                    className="blog-post single-blog-post p-4 shadow rounded position-relative overflow-hidden">
                                    <div className="blog-content">
                                        <div dangerouslySetInnerHTML={{__html: blog.content}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>}
        </React.Fragment>
    )
}
