import React from "react";
import useScripts from "../../utils/hooks/useScripts";

export default function GalleryWrapper({data}) {

    useScripts('js/app.js');

    return (
        <div className="container-fluid mt-100 mt-60">
            <div className="row projects-wrapper">
                {data.slice(0, 8).map(item => (
                    <div className="col-lg-3 col-md-6 col-12 p-0">
                        <div className="work-container work-grid position-relative d-block overflow-hidden">
                            <a className="mfp-image d-inline-block" href={item.file} title="">
                                <img src={item.file} className="img-fluid" alt="work-image"/>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
