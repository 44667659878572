import React from "react";
import useScripts from "../../utils/hooks/useScripts";
import Navigation from "../../components/Navigation/Navigation";
import {isLoaded, useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {Pane, Spinner} from "evergreen-ui";

export default function GoverningBody() {

    useScripts('/js/jquery.min.js');
    useScripts('/js/bootstrap.bundle.min.js');
    useScripts('/js/feather.min.js');
    useScripts('/js/jquery.magnific-popup.min.js');
    useScripts('/js/magnific.init.js');
    useScripts('/js/isotope.js');
    useScripts('/js/portfolio.init.js');
    useScripts('/js/swiper.min.js');
    useScripts('/js/app.js');

    useFirestoreConnect(['pages/governingbody'])

    const gov = useSelector((state: any) => state.firestore.data.pages)

    return (
        <React.Fragment>
            <React.Fragment>
                <Navigation/>

                <section className='section'>
                    <div className="container mt-100 mt-60">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="section-title text-center mb-4 pb-2">
                                    <h2 className="title mb-0">Governing Body</h2>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className='mt-5'>
                            {!isLoaded(gov) && <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                                <Spinner />
                            </Pane>}
                            {isLoaded(gov) && gov.governingbody !== undefined && <div dangerouslySetInnerHTML={{__html: gov.governingbody.state}}></div>}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        </React.Fragment>
    )
}
