import React from "react";
import useScripts from "../../utils/hooks/useScripts";

export default function SwiperMain({images}) {

    useScripts('js/swiper.init.js');
    useScripts('js/app.js');

    return (
        <section className="swiper-slider-hero position-relative d-block vh-100" id="home">
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    {images.map(item => (<div className="swiper-slide d-flex align-items-center overflow-hidden">
                        <div className="slide-inner slide-bg-image d-flex align-items-center"
                             style={{
                                 background: `url('${item.value.url}')center center`,
                                 backgroundSize: "cover"
                             }} data-background={item.value.url}>
                            <div className="bg-overlay"></div>
                            <div className="container">
                                <div className="row pt-md-5 mt-lg-0">
                                    <div className="col-12">
                                        <div className="title-heading">
                                            <h1 className="heading text-white font-weight-bold mb-4">Affiliated to Dibrugarh University</h1>
                                            <p className="para-desc text-white-50">Approved by BAR COUNCIL OF INDIA, New Delhi</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>))}
                </div>
                <div className="swiper-pagination"></div>
                <div className="swiper-button-next border rounded text-center"></div>
                <div className="swiper-button-prev border rounded text-center"></div>
            </div>
        </section>
    )
}
