import useScripts from "../../utils/hooks/useScripts";
import moment from "moment";
import React from "react";
import {isLoaded, useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {Pane, Spinner} from "evergreen-ui";
import {useNavigation} from "react-navi";

export default function AllBlogs() {

    useScripts('js/owl.carousel.min.js');
    useScripts('js/owl.init.js');

    useFirestoreConnect([{
        collection: 'blogs',
        orderBy: ['createdAt', 'desc']
    }]);

    const navig = useNavigation()

    const blogs = useSelector((state: any) => state.firestore.ordered.blogs);

    return (
        <div className="container mt-100 mt-60">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="section-title text-center mb-4 pb-2">
                        <h4 className="title mb-4">Blogs</h4>
                    </div>
                </div>
            </div>

            <div className="row">
                {!isLoaded(blogs) && <Pane display='flex' justifyContent='center'>
                    <Spinner/>
                </Pane>}
                {isLoaded(blogs) && blogs.map(item => (
                    <div className="col-lg-6 col-md-6 mt-4 pt-2">
                        <div className="job-listing p-4 rounded shadow position-relative overflow-hidden">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-12 mt-4 mt-lg-0">
                                    <div className="content text-center text-lg-left ml-lg-3">
                                        <h4 className="title">
                                            <a href="javascript:;" className="text-dark">{item.blogTitle}</a>
                                        </h4>
                                        <ul className="list-unstyled mb-0">
                                            <li className="list-inline-item mr-3">
                                                <a href="javascript:;"
                                                   className="text-muted small h6 mb-0">@{moment.unix(item.createdAt.seconds).format('DD MMM YYYY')}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12 mt-3 mt-lg-0">
                                    <a href='javascript:;' className="btn btn-block btn-primary" onClick={() => navig.navigate(`/blog/${item.id}`)}>Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
