import React from "react";
import useScripts from "../../utils/hooks/useScripts";
import Navigation from "../../components/Navigation/Navigation";
import SwiperMain from "../../components/Home/SwiperMain";
import {isLoaded, useFirebaseConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {Pane, Spinner} from 'evergreen-ui'
import GalleryWrapper from "../../components/Home/GalleryWrapper";
import NoticeWrapper from "../../components/Home/NoticeWrapper";
import AllBlogs from "../Blogs/AllBlogs";
import {useNavigation} from "react-navi";

export default function HomePage() {

    useScripts('js/jquery.min.js');
    useScripts('js/bootstrap.bundle.min.js');
    useScripts('js/feather.min.js');
    useScripts('js/jquery.magnific-popup.min.js');
    useScripts('js/magnific.init.js');
    useScripts('js/isotope.js');
    useScripts('js/portfolio.init.js');
    useScripts('js/swiper.min.js');
    useScripts('js/app.js');

    useFirebaseConnect([
        '/website/hero/images/',
        '/website/hero/text/',
        '/website/hero/principal/',
        '/website/hero/mission/',
        '/website/hero/vision/',
        '/website/hero/logo/',
        '/website/gallery/',
        '/website/notice/'
    ]);

    const website = useSelector((state: any) => state.firebase.ordered.website);
    const websitedata = useSelector((state: any) => state.firebase.data.website);

    const navig = useNavigation()

    return (
        <React.Fragment>
            <Navigation/>

            {!isLoaded(website) && <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                <Spinner />
            </Pane>}

            {isLoaded(website) && website.hero !== undefined && website.hero.images !== undefined && <SwiperMain images={website.hero.images}/>}

            <div className="container mt-100 mt-60">
                {/*<div className='row align-items-center justify-content-center my-3'>
                    <img src='/images/homepromo.jpeg' style={{objectFit: 'cover'}}/>
                </div>*/}
                <div className="row align-items-center">

                    <div className="col-lg-12 col-md-12 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="section-title ml-lg-5">
                            <h4 className="title mb-4">About the College</h4>
                            <p className="text-muted">The Dr. Rohini Kanta Barua Law College was established in the year 1976 as an outcome of a public meeting of the citizens of Dibrugarh held on 20/06/1976 with Shri Pramode Kumar Das, B.L. in the chair as 'Dibrugarh Law College'. The core objective of setting up the institution was 'to create a new avenue at Dibrugarh for imparting legal education'. The College is situated in the heart of the Dibrugarh town and is permanently affiliated to Dibrugarh University and approved by the Bar Council of India, New Delhi. On 04/02/1994, the Dibrugarh Law College was renamed as 'Dr.Rohini Kanta Barua Law College' as late Muktalata Barua wife of Dr. Rohini Kanta Barua, a well known name in scientific research of high order and academic excellence donated the land and building (present administrative wing) to the College to commemorate the illustrious name of her husband. Since then, Dr. Rohini Kanta Barua Law College has not looked back and has developed the facilities for students one after another as per requirement of the Bar Council of India. Dr. R.K.B. Law College offers a rich environment for studying law and prepares students to enter into legal profession through Three Year LL.B and Five Year Integrated BBA,LL.B Programme. A good number of students passing out of this College are doing well in different Bars of the State, Judiciary and also in other fields and thus contributing to the society at large.</p>
                        </div>
                    </div>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-12">
                            <div className="position-relative">
                                <img src="images/51.jpg"
                                     className="rounded-pill img-fluid shadow mx-auto d-block" alt=""/>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h4 className="title mb-4">Message From the Principal</h4>
                            <p className="text-muted para-desc">With great pleasure, I welcome you all to Dr. Rohini Kanta Barua Law College, one of the pioneer law colleges of North East India that is striving hard to impart quality education and making sincere effort to contribute to the society by providing the right kind of human resource. Our college has a rich legacy of academic excellence and has always been a preferred destination for legal studies in the entire North East India.<br/>
	Legal education is one of the strongest ways to mitigate social evils and to establish a just society. In this context, our dedicated teachers guide the students to develop skills of critical thinking, to question assumptions and seek solutions of the problems around us which are invaluable to shine in the legal profession as well as to establish oneself as leader of the society. We are committed to providing you with an environment that fosters intellectual curiosity, innovation and creativity. Our constant endeavour is to keep the college environment vibrant by organising various activities such as Moot Court Competition, Debate Competition, Awareness Programme, Street Plays, etc. and also by facilitating frequent visits by dignitaries from diverse fields of knowledge.<br/>
So, I warmly welcome you all to this prestigious institution to grab the wonderful journey of legal education. Let us work together to create a law literate society through the power of education.
</p>
                            <p className="text-muted para-desc mb-0">Thank you.</p>
                            <p className="text-muted para-desc mb-0"><strong>Dr. Gautomi Dutta Borah</strong></p>
                            <p className="text-muted para-desc mb-0">Principal,</p>
                            <p className="text-muted para-desc mb-0">Dr. Rohini Kanta Barua Law College</p>
                            <p className="text-muted para-desc mb-0">Dibrugarh</p>
                        </div>
                    </div>
                </div>

                <div className="container mt-100 mt-60">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="space-feature rounded shadow p-4">
                                        <i data-feather="eye" className="fea icon-md"></i>
                                        <div className="content mt-4">
                                            <h5><a href="javascript:void(0)" className="text-dark">Our Vision</a>
                                            </h5>
                                            <p className="text-muted mb-0">Our vision is to be a centre of excellence in the field of legal education.</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div className="space-feature rounded shadow p-4">
                                        <i data-feather="crosshair" className="fea icon-md"></i>
                                        <div className="content mt-4">
                                            <h5><a href="javascript:void(0)" className="text-dark">Our Mission</a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                                We are committed to :
                                                <p>a.	 develop an intellectually stimulating, horizon-expanding and participatory teaching learning atmosphere where students can develop conceptual, research oriented and other intellectual skills and thus enable to make better choice in their lives and become better citizens.</p>
                                                <p>b.	 develop a socio cultural structure and process it in all its various forms, intimately connected with the production and dissemination of fundamental knowledge on law and thereby accelerate the process of establishing rule of law in the our country.</p>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isLoaded(websitedata) && websitedata.gallery !== undefined && websitedata.gallery.data !== undefined && <GalleryWrapper data={websitedata.gallery.data}/>}


                {isLoaded(website) && website.notice !== undefined && <NoticeWrapper data={website.notice}/>}

                <AllBlogs/>

            </section>


        </React.Fragment>
    )
}
