import React from "react";
import Navigation from "../../components/Navigation/Navigation";
import useScripts from "../../utils/hooks/useScripts";
import {isLoaded, useFirebaseConnect, useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import moment from "moment";
import Blink from 'react-blink-text';

export default function NoticeArchive() {
    useScripts('/js/jquery.min.js');
    useScripts('/js/bootstrap.bundle.min.js');
    useScripts('/js/feather.min.js');
    useScripts('/js/jquery.magnific-popup.min.js');
    useScripts('/js/magnific.init.js');
    useScripts('/js/isotope.js');
    useScripts('/js/portfolio.init.js');
    useScripts('/js/swiper.min.js');
    useScripts('/js/app.js');

    useFirestoreConnect([{
        collection: 'notices',
        where: ['archive', '==', true],
        storeAs: 'arhivenotice'
    }]);

    const notices = useSelector((state: any) => state.firestore.ordered.arhivenotice);


    return (
        <React.Fragment>
            <Navigation/>

            <section className='section'>
                <div className="container mt-100 mt-60">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h2 className="title mb-0">Archived Notice</h2>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        {isLoaded(notices) && notices.map(item => (
                            <div className="col-lg-12 col-md-6 mt-4 pt-2">
                                <div className="job-listing p-4 rounded shadow position-relative overflow-hidden">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-12 mt-4 mt-lg-0">
                                            <div className="content text-center text-lg-left ml-lg-3">
                                                <h4 className="title">
                                                    <span className="badge badge-primary rounded-pill mr-1"> New </span>
                                                    <Blink color='#00c9a7' text={item.title} fontSize='20'>{item.title}</Blink>
                                                    <a href="javascript:;"
                                                       className="text-muted small h6 mb-0">@{moment.unix(item.createdAt.seconds).format('DD MMM YYYY')}</a>
                                                </h4>
                                                <ul className="list-unstyled mb-0">
                                                    <li className="list-inline-item mr-3">
                                                        <span className="text-muted">{item.desc}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-12 mt-3 mt-lg-0">
                                            {item.fileUrl !== '' && <a href={item.fileUrl} target='_blank' className="btn btn-block btn-primary">Download Now</a>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
