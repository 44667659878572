import React, {useEffect, useState} from "react";
import {isLoaded, useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {useCurrentRoute, useNavigation} from "react-navi";
import clsx from "clsx";
import Typewriter from 'typewriter-effect/dist/core';
import {MobileOnlyView, isMobile} from 'react-device-detect'

export default function Navigation() {

    useFirestoreConnect([
        {
            collection: 'committee'
        }
    ]);

    const navig = useNavigation();
    const currernt = useCurrentRoute();
    const [init, setInit] = useState(false)
    const [high, setHigh] = useState(false);

    const committee = useSelector((state: any) => state.firestore.ordered.committee);

    useEffect(() => {
        if (!init) {
            const instance = new Typewriter('#heading-typewritter', {
                strings: ['Dr. R.K.B Law College', 'ড° ৰোহিনীকান্ত বৰুৱা আইন মহাবিদ্যালয়'],
                autoStart: true,
                loop: true,
                deleteSpeed: 10
            });
            const instance2 = isMobile && new Typewriter('#heading-typewritter2', {
                strings: ['Dr. R.K.B Law College'],
                autoStart: true,
                loop: true,
                deleteSpeed: 10
            });
            setInit(true)
        }
    });

    useEffect(() => {
        if (!high) {
            setHigh(true)
            setTimeout(() => {
                setHigh(true)
                setHigh(false)
            }, 2000);
        }
    }, [high])

    return (
        <React.Fragment>
            <div className="tagline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between">
                                <ul className="list-unstyled mb-0 tag-social">
                                    <li className="list-inline-item mr-2"><a href="javascript:void(0)"
                                                                             className="text-dark">
                                        <span style={{fontWeight: "bold", fontSize: 20}} className='text-primary' id='heading-typewritter'>
                                            Dr. R.K.B Law College
                                        </span>
                                    </a></li>
                                </ul>

                                <ul className="list-unstyled mb-0 tag-right d-flex align-items-center">
                                    <li>
                                        <a href="javascript:void(0)" onClick={() => navig.navigate('/notice')} className={clsx('btn btn-sm mr-5 font-weight-bold', 'blink', {highlight: high})}>Notices</a>
                                    </li>
                                    <li className="list-inline-item mr-4">
                                        <a href="https://www.aijipca.com/" className="text-primary"><i data-feather="pen-tool" className="fea icon-sm"></i> Journal</a>
                                    </li>
                                    <li className="list-inline-item mr-4">
                                        <a href="http://rkblaw-opac.koha.co.in/" className="text-primary"><i data-feather="book-open" className="fea icon-sm"></i> Library</a>
                                    </li>
                                     <li className="list-inline-item mr-4">
                                         <a href="https://www.rkbalumni.in/" className="text-primary"><i data-feather="users" className="fea icon-sm"></i> Alumni</a>
                                     </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <header id="topnav" className="defaultscroll sticky taglines" style={{marginTop: 10}}>
                <div className="container">
                    <div>
                        <a className="logo" href="#">
                            <img src="images/logo.jpg" className="l-dark" height="30" alt=""/>
                                <img src="images/logo.jpg" className="l-light" height="30" alt=""/>
                            {isMobile && <span className='text-primary ml-1' id='heading-typewritter2'></span>}

                        </a>
                    </div>
                    <div className="menu-extras">
                        <div className="menu-item">
                            <a className="navbar-toggle">
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div id="navigation">
                        <ul className={clsx({
                            'navigation-menu': true,
                            'nav-light': currernt.url.pathname === '/'
                        })}>
                            <li><a href="javascript:void(0)" onClick={() => navig.navigate('/')}>Home</a></li>
                            <li><a href="javascript:void(0)" onClick={() => navig.navigate('/admissions')}>Admission</a></li>
                            <li><a href="javascript:void(0)" onClick={() => navig.navigate('/faculty')}>Faculty</a></li>
                            <li><a href="javascript:void(0)" onClick={() => navig.navigate('/infrastructure')}>Infrastructure</a></li>
                            <li><a href="javascript:void(0)" onClick={() => navig.navigate('/governing')}>Governing Body</a></li>

                            {isLoaded(committee) && <li className="has-submenu">
                                <a href="javascript:void(0)">Committees</a><span className="menu-arrow"></span>
                                <ul className="submenu">
                                    {committee.map(item => (
                                        <li key={item.id}><a href="javascript:void(0)" onClick={() => navig.navigate(`/committee/${item.id}`)}>{item.name}</a></li>
                                    ))}
                                </ul>
                            </li>}

                            <li className="has-submenu">
                                <a href="javascript:void(0)">IQAC</a><span className="menu-arrow"></span>
                                <ul className="submenu">
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/iqac/iqacinfo')}>About IQAC</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/iqac/activity')}>IQAC Activity</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/iqac/proceedings')}>Proceedings</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/iqac/feedback')}>Feedback</a></li>
                                    {/*<li><a href="page-team.html">FeedBack</a></li>
                                    <li><a href="page-faq.html">Reports</a></li>
                                    <li><a href="page-faq.html">SSR</a></li>
                                    <li><a href="page-faq.html">NAAC Accreditation</a></li>*/}
                                </ul>
                            </li>

                            <li className="has-submenu">
                                <a href="javascript:void(0)">Publications</a><span className="menu-arrow"></span>
                                <ul className="submenu">
                                    <li><a href="javascript:void(0)" onClick={() => window.location.href="https://drive.google.com/file/d/1Ho4EObucp0oqefUl6rJuU0KeoZLfiPy9/view?usp=sharing"}>Fortiori Reflecto Vol. 1</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => window.location.href="https://drive.google.com/file/d/1WOf8RjQX0sGa4VV6zCtS5rHs8-o4TFmH/view?usp=sharing"}>Newsletter Vol. 1 Issue 1</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => window.location.href="https://drive.google.com/file/d/1wgDsgyEVfgpstbWZs0gNx18o6zCCQVnG/view?usp=sharing"}>Newsletter Vol. 1 Issue 2</a></li>
                                </ul>
                            </li>
                            
                            <li className="has-submenu">
                                <a href="javascript:void(0)">Students Corner</a><span className="menu-arrow"></span>
                                <ul className="submenu">
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/studentsc/constitution')}>Constitution of RKBLSU</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/studentsc/codeofconduct')}>Code of Conduct</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/studentsc/rkblsu')}>RKBLCSU</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/studentsc/activityrkblsu')}>Gallery</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/studentsc/achivements')}>Achivements</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/studentsc/dresscode')}>Dress Code</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/studentsc/bankdetails')}>Bank Details</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/studentsc/qpapers')}>Question Paper</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/studentsc/academic')}>Academic Calender</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/studentsc/holiday')}>Holiday List</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/studentsc/syllabus')}>Syllabus</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => navig.navigate('/studentsc/career')}>Career Prospects</a></li>
                                </ul>
                            </li>

                            {/*<li><a href="index.html">Alumini</a></li>*/}

                        </ul>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}
