import Navigation from "../../components/Navigation/Navigation";
import React from "react";
import useScripts from "../../utils/hooks/useScripts";
import {useNavigation} from "react-navi";

export default function Admissions() {

    useScripts('js/jquery.min.js');
    useScripts('js/bootstrap.bundle.min.js');
    useScripts('js/feather.min.js');
    useScripts('js/jquery.magnific-popup.min.js');
    useScripts('js/magnific.init.js');
    useScripts('js/isotope.js');
    useScripts('js/portfolio.init.js');
    useScripts('js/swiper.min.js');
    useScripts('js/app.js');

    const navig = useNavigation()

    return (
        <React.Fragment>
            <Navigation/>
            <section className="bg-half-170 bg-animation-left half-home shadow d-table w-100">
                <div className="container">
                    <div className="row align-items-center position-relative" style={{zIndex: 1}}>
                        <div className="col-lg-6">
                            <img src="/images/adms.jpg" className="img-fluid rounded" alt=""/>
                        </div>

                        <div className="col-lg-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                            <div className="title-heading ml-lg-4">
                                <h1>Admission</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div
                                className="blog-post single-blog-post p-4 shadow rounded position-relative overflow-hidden">
                                <div className="blog-content">
                                    <h4 className='text-primary'>Eligibility for admission</h4>
                                    <h4 style={{ color: 'blue' }}>Integrated Degree Program (5 year Integrated BBA, LL.B)</h4>
                                    <p className="text-muted">An applicant who has successfully completed Senior Secondary School course (‘+2’) or equivalent from a recognized University of India or outside or from a Senior Secondary Board or equivalent, constituted or recognized by the Union or by a State Government, may apply for admission to 5 year BBA,LL.B program.<br></br>
Provided that applicants who have obtained 10+ 2/ Higher Secondary Pass Certificate  after prosecuting studies in distance or correspondence method shall also be considered as eligible for admission in the Integrated Five Year program.<br></br>
An applicant having minimum percentage of marks not below 45% of the total marks in case of General category , 42% of the total marks in case of OBC/MOBC category and 40% of the total marks in case of SC and ST category obtained in the qualifying examination is eligible for pursuing integrated law degree program.<br></br>
	However the applicants who have obtained 10+2 through open universities system 	directly without having any basic qualifications for prosecuting such studies are not 	eligible for admission in law program. Further, the minimum qualifying marks as 	mentioned above, shall not automatically entitle an applicant to get admission into the 	college, unless he/she fulfills other institutional criteria notified by the college at the 	time of admission.</p>
                                   <h4 style={{ color: 'blue' }}>Three Year Law Degree Course (LL.B)</h4>
                                    <p className="text-muted">An applicant who has graduated in any discipline of knowledge with at least 45% marks in major or aggregate from a University established by an Act of Parliament or by a State legislature or an equivalent national institution recognized as a Deemed to be University or foreign University recognized as equivalent to the status of an Indian University by an authority competent to declare equivalence, may apply for a three year degree program in law. However, relaxations up to 5% of total marks in respect of SC and ST candidates and up to 3% in case of OBC/MOBC candidates are allowed as per norms of the Bar Council of India. Further, the minimum qualifying marks as mentioned above, shall not automatically entitle an applicant to get admission into the college, unless he/she fulfills other institutional criteria notified by the college at the time of admission.<br></br>
The applicants who have obtained 10+2 or graduation / post graduation through open universities system directly without having any basic qualifications for prosecuting such studies are not eligible for admission in law program. </p>
                                    <h4 className='text-primary'>Age limit for admission (BBA, LL.B./LL.B)</h4>
                                    <p className="text-muted">The Bar Council of India has not imposed any age restriction for pursuing BBA, LL.B. and LL.B degree.</p>
                                    <h4 className='text-primary'>Admission Procedure (BBA, LL.B./LL.B)</h4>
                                    <p className="text-muted">Admission to the BBA,LL.B./ LL.B course is based on merit at the qualifying examination. Intending students are required to submit the filled up application form along with the requisite documents on or before the date as notified by the College from time to time. After verification of documents and payment of fees in the bank account of the College maintained at State Bank of India, Dibrugarh Branch located at Thana Charali, their provisional admission will be confirmed. The student is required to deposit the counterfoil of deposit slip/ transaction details in the College Office to get Admission Receipt from the College. The admissions confirmed by the college are subject to approval of the Dibrugarh University.<br></br>
The fee for admission is inclusive of admission fee, tuition fee, library fee, etc. payable in single installment per anuum at the time of admission.
</p>
                                  <p className="text-muted">To promote cashless economy the College accepts admission and other fee through electronic modes only. The fee for admission is inclusive of admission fee, tuition fee, library fee etc. payable in single installment per anuum at the time of admission. </p>
                                    <h4 className='text-primary'>Reservation of Seats</h4>
                                    <p className='text-muted'>
                                        <p>Schedule Caste:	 	7%</p>
                                        <p>Schedule Tribes (Plains):	10%</p>
                                        <p>Schedule Tribes (Hills):	 5%</p>
                                        <p>OBC & MOBC:			15%</p>
                                    </p>
                                    <h4 className='text-primary'>Cancellation of Seats</h4>
                                    <p className='text-muted'>A Student may forfeit his seat under the following circumstance:</p>
                                    <ul className="list-unstyled feature-list text-muted mb-0">
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>A student shall forfeit the seat if she/he fails to attend classes without valid ground and permission of the authority for consecutive 15 days from the date of commencement of the classes.
                                        </li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>The name of the student shall also be struck of the rolls if she/ he is admitted to an equivalent course under any other college/institution affiliated to Dibrugarh University during the Academic session in which she/he is admitted to this College.
                                        </li>
                                    </ul>
                                    <h4 className='text-primary'>Admission into Next Year</h4>
                                    <p className='text-muted'>Students will be required to enroll themselves in the next year classes within 15 days after the completion of their end-semester examination.</p>
                                    <h4 className='text-primary'>Examination and evaluation</h4>
                                    <p className='text-muted'>The scheme of examination shall be of two parts. There will be written End Semester examinations of 80 marks of 3 hour duration  out of total 100 marks, as per the Academic Calendar of the University or as notified by the University from time to time. Remaining 20 marks will be for Internal Assessment to be conducted as follows :</p>
                                    <ul className="list-unstyled feature-list text-muted mb-0">
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>First Sessional Examination = 5 marks</li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>Second Sessional Examination = 5 marks</li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>Group Discussion/ Seminar/Home Assignment = 5 marks.</li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>Viva-Voce Examination = 5 marks</li>
                                    </ul>
                                    <p className='text-muted'>There shall be no provision for repeat/betterment in the sessional examination. However, if a student misses any sessional examination for unavoidable reasons, then the concerned teacher may conduct a special examination  of his/her subject at her/his own discretion considering the genuineness for absence.</p>
                                    <p className='text-muted'>Students shall have to compulsorily attend internal assessments, otherwise, they will not be allowed to appear in the End-Semester examination.</p>
                                    <h4 className='text-primary'>Eligibility for end semester examination</h4>
                                    <p className='text-muted'>A student shall be allowed to appear in the End Semester Examination  if he/she has attended  minimum of 75% of the classes held in the semester concerned including the tutorials, moot court exercises, practical training etc. The attendance status of a student in a particular semester shall be calculated on average basis. They should also have to appear all the components of internal assessments.</p>
                                    <h4 className='text-primary'>Rules of standard of passing</h4>
                                    <h4 style={{ color: 'blue' }}>BBA, LL.B</h4>
                                    <ul className="list-unstyled feature-list text-muted mb-0">
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>A student shall have to secure at least 40% marks in aggregate in the Internal Assessment and End Semester examination to pass a theory/practical/clinical programme.</li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>A candidate shall be declared as pass in a semester, provided she/he passes all the programmes of a semester independently.</li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>The marks secured by a student in Internal Assessment shall be carried over for declaring the result of any End-Semester.</li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>A candidate who could not appear or fail in any End-Semester examination shall be allowed to appear the same as follows
                                            <ul className="list-unstyled feature-list text-muted mb-0">
                                                <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>  i. First Semester Examination with the regular third or odd semester examinations.</li>
                                                <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>ii. Second Semester examination with the regular fourth or even semester examinations and so on.</li>
                                            </ul>
                                        </li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i> A candidate shall have to clear the 5 year BBA, LL.B within a period of 8 (Eight) years from the date of her/his admission in the first semester of the Programme.</li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i> The End Semester Examination shall be conducted as per the provisions of the Dibrugarh University Examination Ordinance.</li>
                                    </ul>
                                    <h4 style={{ color: 'blue' }}>LL.B</h4>
                                    <ul className="list-unstyled feature-list text-muted mb-0">
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>A student shall have to secure at least 40% marks in aggregate in the Internal Assessment and End Semester examination to pass a theory/practical/clinical programme.</li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>A candidate shall be declared as pass in a semester, provided she/he passes all the programmes of a semester independently.</li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>The marks secured by a student in Internal Assessment shall be carried over for declaring the result of any End-Semester.</li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>A candidate who could not appear or fail in any end-semester examination shall be allowed to appear the same as follows
                                            <ul className="list-unstyled feature-list text-muted mb-0">
                                                <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i> i. First Semester Examination with the regular third or odd semester examinations.</li>
                                                <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>ii. Second Semester examination with the regular fourth or even semester examinations and so on.</li>
                                            </ul>
                                        </li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i> A candidate shall have to clear the 3 year LL.B./LL.B (Hons) within a period of 6 (six) years from the date of her/his admission in the first semester of the Programme. There shall be no further special chance after expiry of the time limit as mentioned above.</li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i> The End Semester Examination shall be conducted as per the provisions of the Dibrugarh University Examination Ordinance.</li>
                                    </ul>
                                    
                                    <h4 className='text-primary'>Rules of practical training</h4>
                                    <h4 style={{ color: 'blue' }}>BBA,LL.B & LL.B</h4>
                                    <ul className="list-unstyled feature-list text-muted mb-0">
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>
                                            Under the scheme of Practical Training Programme, each student of LL.B/ Integrated LL.B shall complete 12 weeks/ 20 weeks of Internship training from the 3rd semester onwards   under NGO, Trial and Appellate Advocates, Judiciary, Legal Regulatory Authorities and Parliament, other Legal Functionaries, Market Institutions, Law Firms, Companies, Local self Government and other such bodies where law is practiced either in action or dispute resolution or in management.<br></br>
                                            Provided that internship in any year cannot be for a continuous period of more than four weeks and all students shall at least have gone through once in the entire academic period with Trial and Appellate Advocates.<br></br>
                                            The students have to maintain record of their internship in the Diary provided by the College which will be evaluated with their paper 6.6 in 6th Semester in LL.B & paper 10.4 P3 in 10th Semester of BBA,LL.B.
                                        </li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>Students under practical training paper shall attend the offices of advocates on such days and during such timings as the concerned advocates may suggest. The object of this course is to enable a student to make him conversant with the working in an Advocate as to how he takes instructions from clients and so on. The observation made by the student shall be recorded in the diary under practical training paper and the same shall be assessed for allotted marks under paper 6.6 of 6th Semester in LL.B Programme & 10.4 P3 in 10th Semester in BBA, LL.B Programme.</li>
                                        <li><i className="mdi mdi-circle-medium text-primary mdi-18px h5 mr-2"></i>As per syllabus prescribed for practical training paper, a Viva-Voce Examinations shall be held on behalf of the University is presence of one external examiner to be appointed by the University who will ask questions to the students on the basis of the clinical work done by them and marks will be allotted to them accordingly. The students shall submit their report prepared under various practical papers at the time of viva-voce examination.</li>
                                    </ul>
                                    <div style={{marginTop: 50}}>
                                        <h4 className='text-primary'>Admissions are open</h4>
                                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <a href="/downloads/Prospectus_New.pdf" target="_blank" className="btn btn-primary btn-sm mr-5">Prospectus</a>
                                            <a href="/downloads/admission.pdf" target="_blank" className="btn btn-primary btn-sm mr-5">Admission Form</a>
                                            <a href='javascript:;' onClick={() => navig.navigate('/trident')} className="btn btn-primary btn-sm mr-5">Apply Online</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
