import {compose} from "redux";
import {lazy, map, mount, route, withView} from "navi";
import Layout from "../components/Layout/Layout";
import {View} from "react-navi";
import React from "react";
import HomePage from "../pages/Home/Home";
import Admissions from "../pages/Admissions/Admissions";
import Faculty from "../pages/Faculty/Faculty";
import Infrastructure from "../pages/Infrastructure/Infrastructure";
import FacultySingle from "../pages/Faculty/FacultySingle";
import GoverningBody from "../pages/GoverningBody/GoverningBody";
import Blogs from "../pages/Blogs/Blogs";
import NoticeArchive from "../pages/Home/NoticeArchive";
import Notice from "../pages/Home/Notices";

export default compose<any>(
    withView((request, context, arg) => <Layout>
        <View/>
    </Layout>),
    mount({
        '/': map((request, context, arg) => route({
            title: 'R.K.B. Law College Home Page',
            view: <HomePage/>
        })),
        '/notice/archive': map((request, context, arg) => route({
            title: 'R.K.B. Law College Archive',
            view: <NoticeArchive/>
        })),
        '/notice': map((request, context, arg) => route({
            title: 'R.K.B. Law College Notices',
            view: <Notice/>
        })),
        '/admissions': map((request, context, arg) => route({
            title: 'R.K.B. Law College Admissions Page',
            view: <Admissions/>
        })),
        '/governing': map((request, context, arg) => route({
            title: 'R.K.B. Law College Governing Body',
            view: <GoverningBody/>
        })),
        '/faculty': map((request, context, arg) => route({
            title: 'R.K.B. Law College Faculty Page',
            view: <Faculty/>
        })),
        '/faculty/:id': map((request, context, arg) => {
            const id = request.params.id
            return route({
                title: 'R.K.B. Law College Faculty Page',
                view: <FacultySingle id={id}/>
            })
        }),
        '/blog/:id': map((request, context, arg) => {
            const id = request.params.id
            return route({
                title: 'R.K.B. Law College Blogs',
                view: <Blogs id={id}/>
            })
        }),
        '/infrastructure': map((request, context, arg) => route({
            title: 'R.K.B. Law College Infrastructure Page',
            view: <Infrastructure/>
        })),
        '/studentsc': lazy(() => import('./StudentsRoutes')),
        '/iqac': lazy(() => import('./IQACRoutes')),
        '/committee': lazy(() => import('./CommitteeRoutes')),
        '/trident': lazy(() => import('./AdmissionRoute')),
    })
)
