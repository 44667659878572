import React from "react";
import Navigation from "../../components/Navigation/Navigation";
import useScripts from "../../utils/hooks/useScripts";
import {isLoaded, useFirebaseConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {Pane, Spinner} from "evergreen-ui";

export default function FacultySingle({id}) {

    useScripts('js/jquery.min.js');
    useScripts('js/bootstrap.bundle.min.js');
    useScripts('js/feather.min.js');
    useScripts('js/jquery.magnific-popup.min.js');
    useScripts('js/magnific.init.js');
    useScripts('js/isotope.js');
    useScripts('js/portfolio.init.js');
    useScripts('js/swiper.min.js');
    useScripts('js/app.js');

    useFirebaseConnect([
        {
            path: '/website/faculty/'
        }
    ]);

    const faculty = useSelector((state: any) => state.firebase.data.website);

    console.log(faculty)


    return (
        <React.Fragment>
            <Navigation/>

            <section className='section'>

                {!isLoaded(faculty) && <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                    <Spinner />
                </Pane>}

                {isLoaded(faculty) && <div>
                    <div className="container mt-100 mt-60">
                        <div className="row align-items-center">
                            <div className="col-lg-2 col-md-2"></div>
                            <div className="col-lg-4 col-md-4">
                                <div className="mr-lg-4">
                                    <img src={faculty.faculty[id].image} className="img-fluid rounded" alt=""/>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title">
                                    <h4 className="title mb-4">{faculty.faculty[id].name}</h4>
                                    <p className="text-muted para-desc text-left">{faculty.faculty[id].title}</p>
                                    <p className="text-muted para-desc text-left">{faculty.faculty[id].designations}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-100 mt-60">
                        <div dangerouslySetInnerHTML={{__html: faculty.faculty[id].details}}></div>
                    </div>
                </div>}
            </section>
        </React.Fragment>
    )
}
