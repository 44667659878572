import Navigation from "../../components/Navigation/Navigation";
import React from "react";
import useScripts from "../../utils/hooks/useScripts";

export default function Infrastructure() {

    useScripts('js/jquery.min.js');
    useScripts('js/bootstrap.bundle.min.js');
    useScripts('js/feather.min.js');
    useScripts('js/jquery.magnific-popup.min.js');
    useScripts('js/magnific.init.js');
    useScripts('js/isotope.js');
    useScripts('js/portfolio.init.js');
    useScripts('js/swiper.min.js');
    useScripts('js/app.js');

    return (
        <React.Fragment>
            <Navigation/>

            <section className='section'>
                <div className="container mt-100 mt-60">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h2 className="title mb-0">Infrastructure</h2>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="row align-items-center mt-5 rounded shadow position-relative overflow-hidden p-3">
                        <div className="col-lg-5 col-md-6">
                            <div className="mr-lg-4">
                                <img src='/images/newblock.jpg' className="img-fluid rounded" alt=""/>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title">
                                <h4 className="title mb-4">Administrative Block</h4>
                                <hr/>
                                <p className="text-muted para-desc text-left">The administration of the College is run from the wooden bungalow which was originally residential building of Dr. Rohini Kanta Barua . The bungalow is one of the heritage building of the Dibrugarh town. The administrative building covers the Principal's office, Vice-Principal's office, Teachers' Common Room, College Office, Internal Quality Assurance Cell and Legal Aid Cell.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-5 rounded shadow position-relative overflow-hidden p-3">

                        <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title">
                                <h4 className="title mb-4">Academic Block</h4>
                                <hr/>
                                <p className="text-muted para-desc text-left">The Academic Block of the College is a two storied building which covers three class rooms, Library of the College, Moot Court Hall and Auditorium of the College.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="mr-lg-4">
                                <img src='https://firebasestorage.googleapis.com/v0/b/rkblawcollege-b7d58.appspot.com/o/website%2Finfra%2FSat%20May%2005%202018%2015%3A04%3A22%20GMT%2B0530%20(India%20Standard%20Time).jpg?alt=media&token=c2a5354c-6def-48c4-868f-7c4101766b32' className="img-fluid rounded" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-5 rounded shadow position-relative overflow-hidden p-3">
                        <div className="col-lg-5 col-md-6">
                            <div className="mr-lg-4">
                                <img src='https://firebasestorage.googleapis.com/v0/b/rkblawcollege-b7d58.appspot.com/o/website%2Finfra%2FSat%20May%2005%202018%2015%3A05%3A14%20GMT%2B0530%20(India%20Standard%20Time).jpg?alt=media&token=cf861b35-0038-4b28-8d63-0f2f04e23cbb' className="img-fluid rounded" alt=""/>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title">
                                <h4 className="title mb-4">Auditorium</h4>
                                <hr/>
                                <p className="text-muted para-desc text-left">The Auditorium of the College was inaugurated on 11th May, 2013 by then Minister of DONER Sri Panban Singh Gatowar , in presence of Sri Nogen saikia, Ex-Chair Person of Axom Sahitya Sabha, District Judge, Dibrugarh, Sri Rumi Phukan, Vice Chancellor of Dibrugarh University Sri Kandarpa Kumar Deka.  The College has a well equipped auditorium with seating capacity of over 200 people which is a regular venue for conference, workshop, seminar etc. The auditorium is also used by the students to exhibit their talents during cultural programmes of the college. In the Auditorium the College organizes various seminars, workshop, awareness programme etc from time to time.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-5 rounded shadow position-relative overflow-hidden p-3">

                        <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title">
                                <h4 className="title mb-4">Library with internet facility</h4>
                                <hr/>
                                {/*<p className="text-muted para-desc text-left">The library is the ‘heart’ of any academic institution and plays an important in all academic activities and more so in law institutions. It is just like a research lab for the law students. To ensure success on the legal field a student must learn the inter relation between the various references and primary law materials. Considering these aspects, Dr. R.K.B. Law College has always been trying to maintain a well-stocked library with a wide variety of law books, reference materials, law journals, magazines, news-papers etc. A highly dedicated staff is always been present for support and help of the students. The library can be used throughout the College working hours. The College library is equipped with computer along with internet facilities. We are looking forward to install more computers shortly for convenience of the students. The internet facility is of immense help to both the students and faculty to get updated information around the world. Apart from that, we are also trying to develop and promote the E-Library facility within a short spun of time.</p>*/}
                                <p className="text-muted para-desc text-left">The college has always been trying to maintain a well stocked library  with wide variety  of law books, reference materials, law journals, magazines, news papers etc. The college library is equipped with computer along with internet facilities and photocopy service to the members of the library. It follows open access system and using KOHA software. Book Lending Service is offered to all library users. Library is administered by the Librarian. Library Development Committee looks into proper administration and development of the college library.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="mr-lg-4">
                                <img src='https://firebasestorage.googleapis.com/v0/b/rkblawcollege-b7d58.appspot.com/o/website%2Finfra%2FSat%20May%2005%202018%2015%3A04%3A50%20GMT%2B0530%20(India%20Standard%20Time).jpg?alt=media&token=e80b8bd9-a3f1-46e4-ad35-e41f7640bb50' className="img-fluid rounded" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-5 rounded shadow position-relative overflow-hidden p-3">
                        <div className="col-lg-5 col-md-6">
                            <div className="mr-lg-4">
                                <img src='https://firebasestorage.googleapis.com/v0/b/rkblawcollege-b7d58.appspot.com/o/website%2Finfra%2FSat%20May%2005%202018%2015%3A26%3A58%20GMT%2B0530%20(India%20Standard%20Time).jpg?alt=media&token=6cdbdf3b-9059-4624-984b-53493de6f283' className="img-fluid rounded" alt=""/>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title">
                                <h4 className="title mb-4">Moot Court Hall</h4>
                                <hr/>
                                <p className="text-muted para-desc text-left">Mooting per se is an activity for students of a law college where they  argue on fictional or hypothetical cases in an artificially created arrangement which is quite alike real court setting.   The object is to make them good debaters and to inculcate the habit to self study and of reading law journals and periodicals. Dr. R.K.B. Law College, provide an exclusive infrastructure for moot court class of the students so that they can acquire excellent practical knowledge.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-5 rounded shadow position-relative overflow-hidden p-3">

                        <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title">
                                <h4 className="title mb-4">Legal Aid Cell</h4>
                                <hr/>
                                <p className="text-muted para-desc text-left">The Legal Aid Cell of Dr. R.K.B Law College has been established in the college premises to extend free legal advice and assistance to the under privileged  sections in the society. As it is a duty of every lawyer to provide legal-aid and assistance, the students of Dr. R.K.B Law College, with the assistance of the faculty and practicing lawyers provide free legal advice to the needy people. The Cell also organizes various Legal-Aid and Awareness camps in the rural areas in the vicinity of Dibrugarh District. The aim of these camps is to spread legal awareness relating to socio- legal issues in day to day life of the downtrodden people.
                                <p>At present the activities of the Cell are carried on by the Faculty in-charge of the Cell under the guidance of the Head of the said Cell. The Faculty in-charge is assisted by number senior students who are appointed as Para Legal Volunteers by the District Legal Services Authority and a few numbers of Student Volunteers appointed by the Head.</p>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="mr-lg-4">
                                <img src='https://firebasestorage.googleapis.com/v0/b/rkblawcollege-b7d58.appspot.com/o/website%2Finfra%2FSat%20May%2005%202018%2015%3A09%3A51%20GMT%2B0530%20(India%20Standard%20Time).jpg?alt=media&token=80afc8f2-62b9-40b0-a588-e1e6831e3170' className="img-fluid rounded" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-5 rounded shadow position-relative overflow-hidden p-3">
                        <div className="col-lg-5 col-md-6">
                            <div className="mr-lg-4">
                                <img src='/images/54.JPG' className="img-fluid rounded" alt=""/>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title">
                                <h4 className="title mb-4">IQAC</h4>
                                <hr/>
                                <p className="text-muted para-desc text-left">
                                    With the object  augment the quality affirmation practices and to safeguard the exhaustive excellence and academic merit, Dr. Rohini Kanta Barua Law College establishment of IQAC in the institution. The functions of internal quality assurance cell are
                                    <ul>
                                        <li>Development and Application of quality benchmarks/parameters for the various academic and administrative activities of the college.</li>
                                        <li>Arrangement for feedback responses from students, parents and other stakeholders on quality related processes.</li>
                                        <li>Organization of intra and inter institutional workshops, seminars and quality related themes and promotion of quality circles.</li>
                                        <li>Documentations of various programmes and activities of the college, leading to quality enhancement.</li>
                                        <li>Acting as a nodal agency for coordinating quality related activities, including adaption and dissemination of good practices.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
