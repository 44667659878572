import React from "react";
import useScripts from "../../utils/hooks/useScripts";
import moment from "moment";
import Blink from 'react-blink-text';
import {isLoaded, useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {useNavigation} from "react-navi";

export default function NoticeWrapper({data}) {

    useScripts('js/owl.carousel.min.js');
    useScripts('js/owl.init.js');

    useFirestoreConnect([{
        collection: 'notices',
        where: ['archive', '==', false],
    }]);
    const notices = useSelector((state: any) => state.firestore.ordered.notices);

    const navig = useNavigation()

    return (
        <div className="container mt-100 mt-60">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="section-title text-center mb-4 pb-2">
                        <h4 className="title mb-4">Notices</h4>
                    </div>
                </div>
            </div>

            <div className="row">
                {isLoaded(notices) && notices.map(item => (
                    <div className="col-lg-12 col-md-6 mt-4 pt-2">
                        <div className="job-listing p-4 rounded shadow position-relative overflow-hidden">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-12 mt-4 mt-lg-0">
                                    <div className="content text-center text-lg-left ml-lg-3">
                                        <h4 className="title">
                                            <span className="badge badge-primary rounded-pill mr-1"> New </span>
                                            <Blink color='#00c9a7' text={item.title} fontSize='20'>{item.title}</Blink>
                                            <a href="javascript:;"
                                               className="text-muted small h6 mb-0">@{moment.unix(item.createdAt.seconds).format('DD MMM YYYY')}</a>
                                        </h4>
                                        <ul className="list-unstyled mb-0">
                                            <li className="list-inline-item mr-3">
                                                <span className="text-muted">{item.desc}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12 mt-3 mt-lg-0">
                                    {item.fileUrl !== '' && <a href={item.fileUrl} target='_blank' className="btn btn-block btn-primary">Download Now</a>}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='mt-2 d-flex justify-content-center align-items-center'>
                <a className='btn btn-primary btn-sm' onClick={() => navig.navigate('/notice/archive')}>Archive</a>
            </div>
        </div>
    )
}
