import firebase from "firebase";
import {combineReducers, createStore} from "redux";
import {firebaseReducer} from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore';

const firebaseConfig = {
    apiKey: "AIzaSyB-vYegLm7Dfum1qr2RdVCfN2wsf-knhzE",
    authDomain: "rkblawcollege-b7d58.firebaseapp.com",
    databaseURL: "https://rkblawcollege-b7d58.firebaseio.com",
    projectId: "rkblawcollege-b7d58",
    storageBucket: "rkblawcollege-b7d58.appspot.com",
    messagingSenderId: "301725666812",
    appId: "1:301725666812:web:be6aafed005a1f48daf679"
};

const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer
});

const initialState = {};
export const store = createStore(rootReducer, initialState);

export const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
};
