import React from "react";

export default function Footer() {
    return (
        <React.Fragment>
            <div className="position-relative mt-5">
                <div className="shape overflow-hidden text-light">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <footer className="footer bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                            <a className="logo-footer" href="#">
                                <img src="images/logo.jpg" height="50" alt=""/>
                            </a>
                            <p className="mt-4">
                                Dr. R.K.B. Law College
                            </p>
                            <p>
                                Estd: 1976
                            </p>
                            <p>
                                Affiliated to Dibrugarh University
                            </p>
                            <p>
                                Approved by BAR COUNCIL OF INDIA, New Delhi
                            </p>
                            <hr/>
                            <p>
                                +91-373-2322029
                            </p>
                            <p>
                                dr.rkblawcollege@gmail.com
                            </p>
                        </div>

                        <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h5 className="text-light footer-head font-weight-normal">Quick Links</h5>
                            <ul className="list-unstyled footer-list mt-4">
                                <li><a href="https://www.dibru.ac.in/" className="text-foot"><i
                                    className="mdi mdi-chevron-right mr-1"></i>Dibrugarh University</a></li>
                                <li><a href="http://www.barcouncilofindia.org/" className="text-foot"><i
                                    className="mdi mdi-chevron-right mr-1"></i>Bar Council Of India</a></li>
                                <li><a href="https://www.sci.gov.in/" className="text-foot"><i
                                    className="mdi mdi-chevron-right mr-1"></i>Supreme Court</a></li>
                                <li><a href="https://www.ugc.ac.in/" className="text-foot"><i
                                    className="mdi mdi-chevron-right mr-1"></i>U.G.C</a></li>
                                <li><a href="http://ghconline.gov.in/" className="text-foot"><i
                                    className="mdi mdi-chevron-right mr-1"></i>Gauhati High Court</a></li>
                                <li><a href="http://dibrugarhjudiciary.gov.in/" className="text-foot"><i
                                    className="mdi mdi-chevron-right mr-1"></i>District Judiciary (Dibrugarh)</a></li>
                                <li><a href="http://www.allindiabarexamination.com/" className="text-foot"><i
                                    className="mdi mdi-chevron-right mr-1"></i>All India Bar Examination</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h5 className="text-light footer-head font-weight-normal">Useful Links</h5>
                            <ul className="list-unstyled footer-list mt-4">
                                <li><a href="http://nhrc.nic.in/" className="text-foot"><i
                                    className="mdi mdi-chevron-right mr-1"></i>National Human Rights Commission India</a></li>
                                <li><a href="http://ncw.nic.in/" className="text-foot"><i
                                    className="mdi mdi-chevron-right mr-1"></i> National Commission for Women</a></li>
                                <li><a href="http://ncpcr.gov.in/" className="text-foot"><i
                                    className="mdi mdi-chevron-right mr-1"></i>National Child Protection Commission</a></li>
                                <li><a href="https://nalsa.gov.in/" className="text-foot"><i
                                    className="mdi mdi-chevron-right mr-1"></i>National Legal Service Authority</a></li>
                                <li><a href="http://aslsa.assam.gov.in/" className="text-foot"><i
                                    className="mdi mdi-chevron-right mr-1"></i>State Legal Service Authority</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className="footer footer-bar">
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="text-sm-left">
                                <p className="mb-0">© Copyright 2020 , Dr. R.K.B. Law College , Dibrugarh
                                </p>
                            </div>
                            <div className="text-sm-left">
                                <p className="mb-0">Created with  <i
                                    className="mdi mdi-heart text-danger"></i> by <a href="http://atmiyo.tehcnology"
                                                                                     target="_blank"
                                                                                     className="text-primary">Atmiyo Technologies Evolution OPC Pvt. Ltd.</a>.
                                </p>
                            </div>
                        </div>

                        <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="text-sm-right">
                                <p className="mb-0">
                                    <a href="" className="text-primary">Legal</a><a href="" style={{marginLeft: 20}} className="text-primary">Privacy Policy</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}
