import React, { Suspense } from 'react';
import './App.css';
import {Provider} from "react-redux";
import {rrfProps, store} from "./store/Store";
import {ReactReduxFirebaseProvider} from "react-redux-firebase";
import {Router, View} from "react-navi";
import Routes from "./router/Routes";
import 'antd/dist/antd.css'
import { ThemeProvider } from '@material-ui/core';
import {theme} from "./theme";

function App() {
  return (
      <ThemeProvider theme={theme}>
          <Provider store={store}>
              <ReactReduxFirebaseProvider {...rrfProps}>
                  <Router routes={Routes}>
                      <Suspense fallback={null}>
                          <View/>
                      </Suspense>
                  </Router>
              </ReactReduxFirebaseProvider>
          </Provider>
      </ThemeProvider>
  );
}

export default App;
